import React, { useEffect } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import WhySawirStudio from "../components/whySawirStudio"
import Services from "../components/services"
import Portfolio from "../components/portfolio"
import Contact from "../components/contact"
import Partners from "../components/partners"
import { navigate } from "gatsby"

const IndexPage = () => {
  return (
    <React.Fragment>
      <SEO
        title="Welcome"
        description="Do you need website? Click here for more info about building websites and applications with <SawirStudio/>"
      />
      <Layout>
        <HeroImage
          input={[
            "Ecommerce",
            "Company Profile",
            "Corporate",
            "Online Shop",
            "Portfolio",
            "Personal Blog",
            "Non-Profit",
          ]}
        />
        <div className="py-5 my-5" />
        <WhySawirStudio />
        <div className="py-5 my-5" />
        <Partners />
        <div className="py-5 my-5" />
        <Services />
        <div className="py-5 my-5" />
        <Portfolio />
        <div className="py-5 my-5" />
        <Contact />
        <div className="py-5 my-5" />
      </Layout>
    </React.Fragment>
  )
}

export default IndexPage
