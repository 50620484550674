import React from "react"

const Contact = () => {
  return (
    <React.Fragment>
      <section id="contact">
        <div className="container text-dark">
          <h2 className="text-center">
            <span className="text-secondary">&lt;</span>
            <span className="text-primary">Contact Us</span>
            <span className="text-secondary">/&gt;</span>
          </h2>
          <p className="text-center">
            Don't worry, contacting us is free of charge.
          </p>
          <p className="text-center">
            You can reach us via{" "}
            <a className="text-primary" href="mailto:sawir.ricardo@gmail.com">
              sawir.ricardo@gmail.com
            </a>{" "}
            or fill the form below
          </p>
          <form
            method="POST"
            name="contact"
            netlify-honeypot="bot-field"
            data-netlify="true"
          >
            <p class="hidden" style={{ visibility: "hidden" }}>
              <label>
                Don’t fill this out if you're human: <input name="bot-field" />
              </label>
            </p>
            <div className="form-group">
              <label>Your Name</label>
              <input className="form-control" type="text" name="name" />
            </div>
            <div className="form-group">
              <label>Your Email Address</label>
              <input className="form-control" type="email" name="_replyto" />
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Tell us about your idea or project"
                rows="3"
                name="message"
              />
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary w-100">
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  )
}
export default Contact
