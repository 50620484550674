import React, { useEffect, useState } from "react"
import Typist from "react-typist"

const HeroImage = ({ input }) => {
  return (
    <React.Fragment>
      <div className="hero-image position-relative w-100 bg-dark">
        <div
          className="position-absolute"
          style={{
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
          }}
        >
          <div className="container" style={{ height: "100%" }}>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <h1 className="text-light text-center">
                Build{" "}
                <Typist>
                  <span>Ecommerce</span>
                  <Typist.Backspace count={9} delay={2000} />
                  <span>Company Profile</span>
                  <Typist.Backspace count={15} delay={2000} />
                  <span>Portfolio</span>
                  <Typist.Backspace count={9} delay={2000} />
                  <span>Online Shop</span>
                  <Typist.Backspace count={11} delay={2000} />
                  <span>Personal Brand</span>
                  <Typist.Backspace count={14} delay={2000} />
                  <span>Websites</span>
                </Typist>
                With <span className="text-secondary">&lt;</span>
                <span className="text-primary">SawirStudio</span>
                <span className="text-secondary">/&gt;</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default HeroImage
