import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <React.Fragment>
      <nav className="navbar navbar-dark bg-dark pt-4 pb-5">
        <div className="container">
          <div className="row w-100">
            <div className="col-12 col-md-12 col-lg">
              <Link className="h5" to="/">
                <span className="text-secondary">&lt;</span>SawirStudio
                <span className="text-secondary">/&gt;</span>
              </Link>
              <div>
                <p className="text-light small">
                  Ⓒ{new Date().getFullYear().toString()} Sawir Studio
                </p>
                <p className="text-light small">
                  Made with ❤️ by{" "}
                  <a
                    target="_blank"
                    href="https://sawirstudio.com/about/"
                    className="text-light"
                  >
                    Ricardo Sawir
                  </a>
                </p>
              </div>
            </div>
            <div className="col-12 col-lg">
              <a
                href="https://sawirstudio.com"
                target="blank"
                className="text-light"
              >
                Visit SawirStudio.com
              </a>
            </div>
            <div className="col-12 col-md-6 col-lg">
              <Link to="/#whySawirStudio" className="text-light">
                Why Sawir Studio
              </Link>
            </div>
            <div className="col-12 col-md-6 col-lg">
              <Link to="/#services" className="text-light">
                Services
              </Link>
            </div>
            <div className="col-12 col-md-6 col-lg">
              <Link to="/#portfolio" className="text-light">
                Portfolio
              </Link>
            </div>
            <div className="col-12 col-md-6 col-lg">
              <Link to="/#contact" className="text-light">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}

export default Footer
