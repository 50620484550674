import React from "react"

const Announcement = () => {
  return (
    <React.Fragment>
      <a
        href="https://sawirstudio.com/"
        target="_blank"
        class="text-decoration-none"
      >
        <div class="py-3 bg-dark">
          <div class="container text-center border-bottom pb-4">
            <p class="p-0 m-0 text-warning ">
              Do you need social media development or graphic design service or
              branding? We have <strong>Sawir Studio</strong> for you who need
              graphic design service. <br /> Click HERE to check it out!
            </p>
          </div>
        </div>
      </a>
    </React.Fragment>
  )
}

export default Announcement
