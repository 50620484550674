import React from "react"
import { MdAccessTime, MdAttachMoney, MdMood } from "react-icons/md"

const WhySawirStudio = () => {
  return (
    <React.Fragment>
      <section className="">
        <div className="container">
          <blockquote className="text-center">
            <h2>
              "In {new Date().getFullYear().toString()}, there are more than
              4.39 billion internet users in 2019, an increase of more than 366
              million (9%), versus January 2018."
            </h2>
            <a
              className="text-muted "
              href="https://wearesocial.com/blog/2019/01/digital-2019-global-internet-use-accelerates"
              target="_blank"
              rel="noopener noreferrer"
            >
              wearesocial.com
            </a>
          </blockquote>
          <div className="py-5 py-md-1" />
          <div className="text-center">
            <p className="lead">
              Internet has been a thing. And{" "}
              <span className="text-secondary">&lt;</span>
              <span className="text-primary">SawirStudio</span>
              <span className="text-secondary">/&gt;</span> wants to help you do
              successful business in digital age.
            </p>
          </div>
          <div className="py-5 my-5" />
          <div className="text-center " id="whySawirStudio">
            <h2>
              Why <span className="text-secondary">&lt;</span>
              <span className="text-primary">SawirStudio</span>
              <span className="text-secondary">/&gt;</span>?
            </h2>
            <div className="py-4" />
            <div className="why-sawir-studio row text-dark">
              <div className="col-12 col-md">
                <MdMood size={"4rem"} />
                <div className="py-2" />
                <h5>Save Your Energy</h5>
                <p>
                  It's no secret that building website yourself is possible
                  nowadays.
                  <br />
                  However, isn't it nice if you can focus on handling your
                  business while we take care the hard work for you in building
                  the best website for you?
                </p>
              </div>
              <div className="col-12 col-md">
                <MdAttachMoney size={"4rem"} />
                <div className="py-2" />
                <h5>Save Your Money</h5>
                <p>
                  Creating Website nowadays is affordable. <br />
                  However, building website is not an easy task and tend to be a
                  time creeper, which may result in costing you more money than
                  you expected.
                  <br />
                  We help you to prevent that from happening
                </p>
              </div>
              <div className="col-12 col-md">
                <MdAccessTime size={"4rem"} />
                <div className="py-2" />
                <h5>Save Your Time</h5>
                <p>
                  You may have seen an advertisement about making website as
                  easy as a few clicks.
                  <br />
                  That's true if you only need basic features (such as
                  blogging).
                  <br />
                  We can help you add advanced features fast.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default WhySawirStudio
