import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Portfolio = () => {
  const {
    umeet,
    reyd,
    nike,
    habitatHills,
    vivaldy,
    wedding,
    edlink,
    gilbert,
    dkvItb,
  } = useStaticQuery(graphql`
    {
      umeet: file(name: { eq: "Umeet-portfolio" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      reyd: file(name: { eq: "Reyd-portfolio" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      nike: file(name: { eq: "nike-portfolio" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      habitatHills: file(name: { eq: "habitat-hills-portfolio" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      vivaldy: file(name: { eq: "vivaldy-portfolio" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      wedding: file(name: { eq: "Wedding-portfolio" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      edlink: file(name: { eq: "Edlink-portfolio" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      gilbert: file(name: { eq: "Gilbert-portfolio" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      dkvItb: file(name: { eq: "Dkv-portfolio" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <section id="portfolio">
        <div className="container-fluid">
          <h2 className="text-center">
            <span className="text-secondary">&lt;</span>
            <span className="text-primary">Portfolio</span>
            <span className="text-secondary">/&gt;</span>
          </h2>
          <div className="my-5" />
          <div className="row">
            <div className="col-12 col-md-4">
              <a
                className="text-muted"
                href="https://edlink.sawirstudio.com"
                target="_blank"
              >
                <div
                  className="position-relative w-100"
                  style={{ paddingTop: "67%" }}
                >
                  <Img
                    className="position-absolute w-100"
                    style={{ top: "0", left: "0" }}
                    fluid={edlink.childImageSharp.fluid}
                  />
                </div>
                <div className="my-3" />
                <div className="text-dark text-center text-decoration-none">
                  <h5>Edlink + ConneX</h5>
                  <small>Website (On Progress)</small>
                </div>
              </a>
            </div>
            <div className="col-12 col-md-4">
              <a
                className="text-muted"
                href="https://gilbert60seconds.sawirstudio.com"
                target="_blank"
              >
                <div
                  className="position-relative w-100"
                  style={{ paddingTop: "67%" }}
                >
                  <Img
                    className="position-absolute w-100"
                    style={{ top: "0", left: "0" }}
                    fluid={gilbert.childImageSharp.fluid}
                  />
                </div>
                <div className="my-3" />
                <div className="text-dark text-center text-decoration-none">
                  <h5>Gilbert 60 Detik</h5>
                  <small>Professional Branding</small>
                </div>
              </a>
            </div>
            <div className="col-12 col-md-4">
              <a
                className="text-muted"
                href="https://dkvitb.sawirstudio.com"
                target="_blank"
              >
                <div
                  className="position-relative w-100"
                  style={{ paddingTop: "67%" }}
                >
                  <Img
                    className="position-absolute w-100"
                    style={{ top: "0", left: "0" }}
                    fluid={dkvItb.childImageSharp.fluid}
                  />
                </div>
                <div className="my-3" />
                <div className="text-dark text-center text-decoration-none">
                  <h5>Desain Komunikasi Visual ITB</h5>
                  <small>Website Profile</small>
                </div>
              </a>
            </div>
            <div className="col-12 col-md-4">
              <a className="text-muted" href="https://umeet.id" target="_blank">
                <div
                  className="position-relative w-100"
                  style={{ paddingTop: "67%" }}
                >
                  <Img
                    className="position-absolute w-100"
                    style={{ top: "0", left: "0" }}
                    fluid={umeet.childImageSharp.fluid}
                  />
                </div>
                <div className="my-3" />
                <div className="text-dark text-center text-decoration-none">
                  <h5>Umeet.id</h5>
                  <small>Landing Page</small>
                </div>
              </a>
            </div>
            <div className="col-12 col-md-4">
              <a
                className="text-muted"
                href="https://reyd.sawirstudio.com"
                target="_blank"
              >
                <div
                  className="position-relative w-100"
                  style={{ paddingTop: "67%" }}
                >
                  <Img
                    className="position-absolute w-100"
                    style={{ top: "0", left: "0" }}
                    fluid={reyd.childImageSharp.fluid}
                  />
                </div>
                <div className="my-3" />
                <div className="text-dark text-center text-decoration-none">
                  <h5>Reyd</h5>
                  <small>Ecommerce</small>
                </div>
              </a>
            </div>
            <div className="col-12 col-md-4">
              <a
                className="text-muted"
                href="https://justdoit-nike.netlify.com"
                target="_blank"
              >
                <div
                  className="position-relative w-100"
                  style={{ paddingTop: "67%" }}
                >
                  <Img
                    className="position-absolute w-100"
                    style={{ top: "0", left: "0" }}
                    fluid={nike.childImageSharp.fluid}
                  />
                </div>
                <div className="my-3" />
                <div className="text-dark text-center text-decoration-none">
                  <h5>Nike - Just Do It</h5>
                  <small>E-commerce/Company Profile</small>
                </div>
              </a>
            </div>
            <div className="col-12 col-md-4">
              <a
                className="text-muted"
                href="https://habitathills.netlify.com"
                target="_blank"
              >
                <div
                  className="position-relative w-100"
                  style={{ paddingTop: "67%" }}
                >
                  <Img
                    className="position-absolute w-100"
                    style={{ top: "0", left: "0" }}
                    fluid={habitatHills.childImageSharp.fluid}
                  />
                </div>
                <div className="my-3" />
                <div className="text-dark text-center text-decoration-none">
                  <h5>Habitat Hills</h5>
                  <small>Marketing Page</small>
                </div>
              </a>
            </div>
            <div className="col-12 col-md-4">
              <a
                className="text-muted"
                href="https://vivaldyskinclinic.netlify.com"
                target="_blank"
              >
                <div
                  className="position-relative w-100"
                  style={{ paddingTop: "67%" }}
                >
                  <Img
                    className="position-absolute w-100"
                    style={{ top: "0", left: "0" }}
                    fluid={vivaldy.childImageSharp.fluid}
                  />
                </div>
                <div className="my-3" />
                <div className="text-dark text-center text-decoration-none">
                  <h5>Vivaldy Skin Clinic</h5>
                  <small>Company Profile</small>
                </div>
              </a>
            </div>
            <div className="col-12 col-md-4">
              <a
                className="text-muted"
                href="https://weddingphotographer.netlify.com/"
                target="_blank"
              >
                <div
                  className="position-relative w-100"
                  style={{ paddingTop: "67%" }}
                >
                  <Img
                    className="position-absolute w-100"
                    style={{ top: "0", left: "0" }}
                    fluid={wedding.childImageSharp.fluid}
                  />
                </div>
                <div className="my-3" />
                <div className="text-dark text-center text-decoration-none">
                  <h5>Wedding Photographer</h5>
                  <small>Personal Branding</small>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Portfolio
