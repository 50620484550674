import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Partners = () => {
  const { partnersLogo } = useStaticQuery(graphql`
    {
      partnersLogo: allFile(filter: { relativeDirectory: { eq: "partners" } }) {
        edges {
          node {
            childImageSharp {
              fixed(
                width: 100
                height: 100
                fit: CONTAIN
                background: "#ffffff"
              ) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
      }
    }
  `)
  const partners = partnersLogo.edges.map(({ node }) => {
    return <Img className="mx-3 mt-5" fixed={node.childImageSharp.fixed} />
  })
  return (
    <React.Fragment>
      <div className="container">
        <h2 className="text-center">
          <span className="text-secondary">&lt;</span>
          <span className="text-primary">Partners</span>
          <span className="text-secondary">/&gt;</span>
        </h2>
        <div className="my-5" />
        <div className="d-flex justify-content-center flex-wrap">
          {partners}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Partners
