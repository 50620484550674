import React from "react"
import { MdShoppingCart, MdPeople, MdFace, MdWork } from "react-icons/md"
import { FaBuilding } from "react-icons/fa"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Services = () => {
  const { service } = useStaticQuery(graphql`
    {
      service: file(relativePath: { eq: "sawirstudio-services.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <div id="services">
        <div className="container">
          <h2 className="text-center">
            <span className="text-secondary">&lt;</span>
            <span className="text-primary">Services</span>
            <span className="text-secondary">/&gt;</span>
          </h2>
          <div className="my-5" />
          <div className="row">
            <div className="col-12 col-md-6">
              <h5>We provide services for you who:</h5>
              <ul className="m-0 px-4">
                <li>Want To Start New Business and need to make website</li>
                <li>Freelancers or job-seeker to show online portfolio</li>
                <li>Business owners who are looking to go online</li>
                <li>Expand current online shops to real website</li>
                <li>Looking to update current websites</li>
                <li>Need help in maintaining current website</li>
              </ul>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <div
                className="position-relative w-100"
                style={{ paddingTop: "65%" }}
              >
                <Img
                  className="position-absolute w-100"
                  style={{ top: "0", left: "0" }}
                  fluid={service.childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
          <div className="my-5" />
          <section className="kind-of-websites">
            <h5 className="text-center">We serve these kinds of websites:</h5>
            <div className="text-dark row">
              <div className="col-6 col-md-4 text-center">
                <MdShoppingCart size={"5rem"} />
                <h5>Ecommerce</h5>
              </div>
              <div className="col-6 col-md-4 text-center">
                <MdPeople size={"5rem"} />
                <h5>Company Profile</h5>
              </div>
              <div className="col-6 col-md-4 text-center">
                <MdShoppingCart size={"5rem"} />
                <h5>Online Shop</h5>
              </div>
              <div className="col-6 col-md-4 text-center">
                <MdFace size={"5rem"} />
                <h5>Personal Branding</h5>
              </div>
              <div className="col-6 col-md-4 text-center">
                <MdWork size={"5rem"} />
                <h5>Portfolio</h5>
              </div>
              <div className="col-6 col-md-4 text-center">
                <FaBuilding size={"5rem"} />
                <h5>Corporate</h5>
              </div>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Services
