import React, { useState } from "react"
import { Link } from "gatsby"
import { MdMenu } from "react-icons/md"

const Navbar = () => {
  const [show, setShow] = useState(false)

  function handleCollapse() {
    setShow(!show)
  }

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark shadow">
        <div className="container">
          <Link to="/" className="text-primary navbar-brand">
            <span className="text-secondary">&lt;</span>SawirStudio
            <span className="text-secondary">/&gt;</span>
          </Link>
          <button
            className="navbar-toggler border-0 p-0 "
            type="button"
            onClick={handleCollapse}
          >
            <MdMenu />
          </button>
          <div
            className={`collapse navbar-collapse justify-content-end ${show &&
              "show"}`}
            onClick={handleCollapse}
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/#whySawirStudio">
                  Why Sawir Studio
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/#services">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/#portfolio">
                  Portfolio
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/#contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}

export default Navbar
